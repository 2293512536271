import {React} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import IconClose from '../images/icon_close.png';


const SectionPopupBanner = () => {
    const closePopup = () => {

    }

    return (
        <div className={PWAPreviewStyle.popup} id="popup">
            <div className={PWAPreviewStyle.popup__container}>
                <div onClick={closePopup} className={PWAPreviewStyle.iconClose}>
                    <img src={IconClose} alt=""/>
                </div>
                <div className={PWAPreviewStyle.popup__content}>
                    <img className={PWAPreviewStyle.popup__image} src="" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default SectionPopupBanner;