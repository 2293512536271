import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c943be079e46164c8b37d4569b8ec86d@o4508058995654656.ingest.de.sentry.io/4508059000307792",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.01,
});

window.addEventListener("unhandledrejection", function (event) {
    Sentry.captureException(event.reason);
});

window.onerror = function (message, source, lineno, colno, error) {
    Sentry.captureException(error);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
