import {React} from "../../../imports/imports-packege";
import ActionTitleStyle from "./ActionTitleStyle.module.scss";
const ActionTitle = ({actionText, style}) => {
    return (
        <>
            <p className={ActionTitleStyle.ActionTitle} style={{marginBottom: `${style}px`, marginRight: '15px'}}>{actionText}</p>
        </>
    );
};

export default ActionTitle;