import { React } from '../../imports/imports-packege';
import ErrorPopup from './ErrorHandle';

const OutputError = ({ dataSend }) => {
    if (dataSend.landName === '') {
        return 'Select name PWA';
    }
    if (dataSend.langCode === '') {
        return 'Select language PWA';
    }
    if (dataSend.iconUrl === '') {
        return 'Select icon PWA';
    }
    if (dataSend.screenshotImages.length === 0) {
        return 'Select screenshots PWA';
    }
    if (dataSend.amountComments === '') {
        return 'Enter amount comments';
    }
    if (dataSend.author === '') {
        return 'Enter author name';
    }
    if (dataSend.categories === '') {
        return 'Select categories';
    }
    if (dataSend.age === '') {
        return 'Select age';
    }
    if (dataSend.appSize === '') {
        return 'Select app size';
    }
    if (dataSend.amountInstall === '') {
        return 'Select amount install';
    }
    if (dataSend.landDescriptionTitle === '') {
        return 'Enter Title description';
    }
    if (dataSend.landDescriptionText === '') {
        return 'Enter description text';
    }
    if (dataSend.templateReview.length === 0) {
        return 'Add review';
    }
    return null;
}

export default OutputError;
