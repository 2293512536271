import {React, $} from "../../../imports/imports-packege";
import { AndroidFrame } from "../../../imports/imports-images";
import PWAPreviewStyle from './PWAPreviewStyle.module.css';
import SectionScrollBanner from "./Sections/SectionScrollBanner";
import SectionTop from "./Sections/SectionTop";
import SectionDescription from "./Sections/SectionDescription";
import SectionDataSecurity from "./Sections/SectionDataSecurity";
import SectionRating from "./Sections/SectionReviewRating";
import SectionPopupBanner from "./Sections/SectionPopupBanner";
import SectionReview from "./Sections/SectionReview";
import SectionCategories from "./Sections/SectionCategories";
import SectionRecommendedApp from "./Sections/SectionRecommendedApp";

const PWAPreview = () => {
    return (
        <div className={PWAPreviewStyle.pwa_container}>
            <div className={PWAPreviewStyle.android_frame}>
                <img src={AndroidFrame} alt="" />
            </div>
            <div className={PWAPreviewStyle.contentSection}>
                <SectionTop />
                <SectionScrollBanner />
                <SectionDescription />
                <SectionCategories />
                <SectionDataSecurity />
                <SectionRating />
                <SectionReview />
                <SectionPopupBanner />
                <SectionRecommendedApp />
            </div>
            
        </div>
    );
};

export default PWAPreview;