import {React, useState, useEffect, StatePool, axios, useCallback} from "../../../imports/imports-packege";
import {ActionTitle, SelectList, PopoverInfo, Input, Button} from "../../../imports/imports-components";
import { UploadFileIcon, IconRemoveScreen } from "../../../imports/imports-images";
import { useData } from "../../Context/ContextApp";
import AppDesignStyle from "./AppDesignStyle.module.scss";
import { LanguagePwaList, ListOptionLanguageEnVersion } from "../../SelectOption/SelectOption";
import TranslateBuilderText from '../../TranslatePagesElement/translate-constructor.json';
import '../../../assets/style/AppStyle.css';
import CopyDesign from "./CopyDesign";
import TemplateDesign from "./PwaTemplateDesign/TemplateDesign";


const ParseLink = StatePool.createState(undefined);
const PreviewIcon = StatePool.createState('');

const AppDesign = () => {
    const { data, updateData } = useData();
    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }

    useEffect(() => {
        handleTranslate(translateQueryParameter)
    }, [translateQueryParameter])

    const [appName, setAppName] = useState('');
    const [previewUrl, setPreviewUrl] = StatePool.useState(PreviewIcon);
    const handlerAppName = (query_appName) => {
        setAppName(query_appName);
    };
    useEffect((app_name)=>{
        if (app_name) {
            handlerAppName(app_name);
        }
        updateData({
            ...data,
            landName: appName
        })
    }, [appName]);

    useEffect(() => {
        handlerAppName(data.landName)
    }, [data.landName]);


    const handleIconUrl = (event) => {
        const file = event.target.files[0];
        handleFileChange({ target: { files: [file] } });
        if (file) {
            const previewIcon = new FileReader();
            previewIcon.onload = () => {
                setPreviewUrl(previewIcon.result);
            }

            previewIcon.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const [iconBaseCode, setIconBaseCode] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const fileObject = new File([file], 'icon_resize.png', { type: file.type });
                setIconBaseCode(base64String);
                updateData({
                    ...data,
                    iconUrl: base64String,
                    iconFile:  fileObject
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const [previewScreens, setPreviewScreens] = useState(data.previewScreens || [null, null, null, null, null, null]);

    const handleScreen = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const previewScreen = new FileReader();

            previewScreen.onload = () => {
                const imageUrl = previewScreen.result;
                const updatedScreens = [...previewScreens];
                updatedScreens[index] = imageUrl;
                setPreviewScreens(updatedScreens);

                // Оновлюємо дані в контексті
                updateData({
                    ...data,
                    previewScreens: updatedScreens,
                    [`screen_${index + 1}`]: imageUrl,
                    screenshotImages: {
                        ...data.screenshotImages,
                        [`screen_${index + 1}`]: imageUrl,
                    }
                });
            };
            previewScreen.readAsDataURL(file);
        }
    };


    const handleScreen_1 = (event) => handleScreen(event, 0);
    const handleScreen_2 = (event) => handleScreen(event, 1);
    const handleScreen_3 = (event) => handleScreen(event, 2);
    const handleScreen_4 = (event) => handleScreen(event, 3);
    const handleScreen_5 = (event) => handleScreen(event, 4);
    const handleScreen_6 = (event) => handleScreen(event, 5);


    const [selectedTranslate, setSelectedTranslate] = useState(data.langCode || '');
    useEffect(() => {
        updateData({ ...data, langCode: selectedTranslate });
    }, [selectedTranslate]);
    const handleSelectTranslate = (translate) => {
        setSelectedTranslate(translate);
    }
    useEffect(() => {
        setSelectedTranslate(data.langCode);
    }, [data.langCode]);

    function HideInputLoadImages () {
        let iconBlock = document.getElementById('icon_block');
        let screenBlock = document.getElementById('screen_block');
        iconBlock.classList.add(`${AppDesignStyle.Section__hide}`);
        screenBlock.classList.add(`${AppDesignStyle.Section__hide}`);
    }

    const [LinkParse, setLinkParse] = StatePool.useState(ParseLink);
    const handleLinkParse = (link) => setLinkParse(link);
    const keitaroId = data.keitaroId;


    const sendLinkParse = async () => {
        let url = 'https://pwa-builder.com/api/parse-googleplay?link_parse=';
        // let url = 'http://localhost:8181/api/parse-googleplay?link_parse=';
        // let url = 'https://test.pwa-builder.com/api/parse-googleplay?link_parse=';
        if (!LinkParse) {
            alert('Додайте посилання на гру');
            return;
        }

        function formatContextScreen (screens) {
            let count = 1;
            let contextFormat = {};

            for (let item of screens) {
                contextFormat[`screen_${count}`] = item;
                count++;
            }
            return contextFormat;
        }
        await axios.get(`${url}${LinkParse}&keitaro_id=${keitaroId}`)
            .then((response) => {
                if (response.data.error) {
                    alert('Ой, схоже саме цей додаток не вийде поцупити з Play Market, \b спробуй, будь ласка, інший');
                    return false;
                }
                let iconLink = response.data.icon;
                let screensLink = formatContextScreen(response.data.screen);

                updateData({ ...data, iconUrl: iconLink, screenshotImages: screensLink, parseContents: true});
                HideInputLoadImages();
            });
    }

    useEffect(() => {
        if (LinkParse !== undefined) {
            HideInputLoadImages();
        }
    }, [LinkParse]);

    const handleRemoveImages = useCallback((e) => {
        let targetClose = e.target;
        let dataScreenItem = targetClose.dataset.itemScreen;
        let containerPreviewScreen = targetClose.parentElement.parentElement.dataset.keyItem;
        let updatedScreenshotImages = { ...data.screenshotImages };

        if (updatedScreenshotImages[dataScreenItem]) {
            delete updatedScreenshotImages[dataScreenItem];

            const updatedScreens = [...previewScreens];
            updatedScreens[containerPreviewScreen - 1] = null;

            setPreviewScreens(updatedScreens);

            updateData({
                ...data,
                screenshotImages: updatedScreenshotImages,
                previewScreens: updatedScreens,

                [dataScreenItem]: null
            });
        }
    }, [data, previewScreens, updateData]);


    useEffect(() => {

    }, [data.templateStatus ,previewScreens]);

    const [templateCheckboxStatus, setTemplateCheckBoxStatus] = useState(data.templateStatus);
    const handleTemplatePwa = useCallback(() => {
        setTemplateCheckBoxStatus(prevStatus => !prevStatus);
        updateData({
            ...data,
            templateStatus: !templateCheckboxStatus
        })
    }, [templateCheckboxStatus, data]);

    useEffect(() => {
        setTemplateCheckBoxStatus(data.templateStatus)
    }, [data.templateStatus]);

    useEffect(() => {
        if (!templateCheckboxStatus) {
            setTimeout(() => {
                setPreviewScreens([...data.previewScreens]);
            }, 100);
        }
    }, [templateCheckboxStatus, data.previewScreens]);
    useEffect(() => {
        if (!templateCheckboxStatus) {
            setPreviewScreens(data.previewScreens || [null, null, null, null, null, null]);
        }
    }, [templateCheckboxStatus, data.previewScreens]);

    return (
        <div className={AppDesignStyle.TabContent}>
            <div className={AppDesignStyle.Section}>
                <ActionTitle
                    actionText={TranslateBuilderText[translateCode]['app-page']['name-app']}
                />
                <Input
                    className={AppDesignStyle.InputField}
                    inputValue={handlerAppName}
                    placeholder="App name"
                    valueSave={appName}/>
            </div>
            <div className={`${AppDesignStyle.Section} ${AppDesignStyle.SelectList}`}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['language-app']}/>
                <SelectList
                    optionList={translateCode === 'ua' ? LanguagePwaList : ListOptionLanguageEnVersion}
                    getSelectValue={handleSelectTranslate}
                    value={selectedTranslate}
                />
            </div>

            <div className={AppDesignStyle.Section}>
                <div className={AppDesignStyle.ContainerChecker}>
                    <div className={AppDesignStyle.CheckBoxCustomContainer}>
                        <input
                            type="checkbox"
                            checked={templateCheckboxStatus}
                            onClick={(e) => handleTemplatePwa(e)}
                            className={AppDesignStyle.CheckBoxCustomContainer__input}
                            id="IconImageReviewer"
                        />
                        <label htmlFor="IconImageReviewer"></label>
                    </div>
                    <span>Шаблони ПВА</span>
                </div>
                {
                    templateCheckboxStatus && (
                        <TemplateDesign />
                    )
                }
            </div>

            <div className={AppDesignStyle.Section} style={{display: templateCheckboxStatus ? 'none' : 'block'}}>
                <h2 className={AppDesignStyle.CustomTitle}>{TranslateBuilderText[translateCode]['app-page']['parse-app']}</h2>
                <PopoverInfo
                    TitleText={TranslateBuilderText[translateCode]['app-page']['tooltip-title-parse']}
                    DescriptionText={TranslateBuilderText[translateCode]['app-page']['tooltip-content-parse']}
                />
                <Input
                    inputValue={handleLinkParse}
                    valueSave={LinkParse}
                    placeholder={TranslateBuilderText[translateCode]['app-page']['placeholder-copy-link']}/>

                <div onClick={sendLinkParse}>
                    <Button ButtonText={TranslateBuilderText[translateCode]['app-page']['button-parse']}/>
                </div>
            </div>

            <div className={AppDesignStyle.Section} style={{display: templateCheckboxStatus ? 'none' : 'block'}}>
                <CopyDesign/>
            </div>
            <div className={`${AppDesignStyle.Section}`} id="icon_block"
                 style={{display: templateCheckboxStatus ? 'none' : 'block'}}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['icon']}/>

                <div className={AppDesignStyle.SelectIconBlock}>
                    <div className={AppDesignStyle.DownloadIconApp}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleIconUrl}
                            className={AppDesignStyle.DownloadIconApp_IconButtonDowload}
                        />
                        <img src={UploadFileIcon} className={AppDesignStyle.DownloadIconApp_Icon}
                             alt="Upload File Icon"/>
                        <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                            <span
                                className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{TranslateBuilderText[translateCode]['app-page']['size']}</span>
                            <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>512x512px</span>
                        </p>
                    </div>
                    {previewUrl && (
                        <img src={previewUrl} className={AppDesignStyle.PreviewImage} alt="Preview"/>
                    )}
                </div>
            </div>
            <div className={`${AppDesignStyle.Section}`} id="screen_block"
                 style={{display: templateCheckboxStatus ? 'none' : 'block'}}>
                <ActionTitle actionText={TranslateBuilderText[translateCode]['app-page']['screenshots']}/>
                <div className={AppDesignStyle.ScreenshotList}>
                    {[1, 2, 3, 4, 5, 6].map(index => {
                        const handleScreen = (event, screenIndex) => {
                            switch (screenIndex) {
                                case 1:
                                    handleScreen_1(event);
                                    break;
                                case 2:
                                    handleScreen_2(event);
                                    break;
                                case 3:
                                    handleScreen_3(event);
                                    break;
                                case 4:
                                    handleScreen_4(event);
                                    break;
                                case 5:
                                    handleScreen_5(event);
                                    break;
                                case 6:
                                    handleScreen_6(event);
                                    break;
                                default:
                                    break;
                            }
                        };

                        return (
                            <div className={AppDesignStyle.ContainerItem} key={index}>
                                <div className={`${AppDesignStyle.DownloadIconApp} ${AppDesignStyle.ScreenItem}`}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => handleScreen(event, index)}
                                        className={AppDesignStyle.DownloadMultiScreen_MultiImageButtonDowload}
                                    />
                                    <img src={UploadFileIcon} className={AppDesignStyle.DownloadIconApp_Icon}
                                         alt="Upload File Icon"/>
                                    <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                        <span
                                            className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{TranslateBuilderText[translateCode]['app-page']['size']}</span>
                                        <span
                                            className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>720x1280px</span>
                                    </p>
                                </div>
                                {previewScreens[index - 1] && (
                                    <div className={AppDesignStyle.PreviewImageContainer}
                                         data-item-container={`screen_${index}`} data-key-item={index}>
                                        <p
                                            className={AppDesignStyle.PreviewImageContainer__ContainerIconClose}
                                            data-screen-index={index}
                                            onClick={event => handleRemoveImages(event)}
                                        >
                                            <img
                                                src={IconRemoveScreen}
                                                className={AppDesignStyle.PreviewImageContainer__IconClose}
                                                alt=""
                                                data-item-screen={`screen_${index}`}
                                            />
                                        </p>

                                        <img key={index} src={previewScreens[index - 1]}
                                             className={AppDesignStyle.PreviewImage} alt="Preview"/>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AppDesign;
