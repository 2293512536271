import {React, useEffect, useState, Component} from "../../../imports/imports-packege";
import Switch from "react-switch";
import { useData } from "../../Context/ContextApp";

const Switcher = () => {
    let {data, updateData} = useData();

    const [statusSwitcher, setStatusSwitcher] = useState(false || data.verifiedDeveloper);
    const handleSwitcher = function(checked) {
        setStatusSwitcher(checked)
    };

    useEffect(() => {
        updateData({ ...data, verifiedDeveloper:  statusSwitcher});
    }, [statusSwitcher])

    return (
        <div>
            <Switch onChange={handleSwitcher} checked={statusSwitcher} />
        </div>
    )
}

export default Switcher;