import {React} from "../../imports/imports-packege";
import HeaderButtonStyle from "./HeaderButtonStyle.module.scss";

const HeaderButton = ({HeaderButtonText, onClick}) => {

    return (
        <div className={HeaderButtonStyle.HeaderButton} onClick={onClick}>
            <p className={HeaderButtonStyle.HeaderButton_Text}>{HeaderButtonText()}</p>
        </div>
    );
};

export default HeaderButton;