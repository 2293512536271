import {React, useState} from "../../../imports/imports-packege";
import {InfoIcon} from "../../../imports/imports-images";
import PopoverInfoStyle from "./PopoverInfoStyle.module.scss";

const PopoverInfo = ({TitleText, DescriptionText}) => {

    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => {
        setIsHovering(true);
    };
    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <div className={PopoverInfoStyle.InformationText}>
            <div
                className={PopoverInfoStyle.TitleInformation}
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave} >
                <img src={InfoIcon} className={PopoverInfoStyle.InformationText_Icon} alt="Info Icon"/>
                <p className={PopoverInfoStyle.InformationText_Text}>{TitleText}</p>
            </div>
            {
                isHovering && (
                    <p className={`${PopoverInfoStyle.InformationText_Text} ${PopoverInfoStyle.Description}`}>
                        {DescriptionText}
                    </p>
                )
            }
        </div>
    );
};

export default PopoverInfo;