import {React, StatePool, useEffect, axios, queryString, useState, useRef} from "../../imports/imports-packege";
import {useData} from "../Context/ContextApp";
import PreviewStyle from "./PreviewStyle.module.scss";
import PWAPreview from "./PWAPreview/PWApreview";
import ErrorPopup from '../ErrorHandle/ErrorHandle';
import TranslateBuilderText from '../TranslatePagesElement/translate-constructor.json';
import App from "../../App";
import ShowError from "../ErrorHandle/OutputError";

const Preview = () => {
    const { data, updateData } = useData();

    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);

    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }
    useEffect(() => {
        handleTranslate(translateQueryParameter);
    }, [translateQueryParameter])

    const [landName, setLandName] = useState('');
    const [builderMode, setBuilderMode] = useState('create');
    const [buttonText, setButtonText] = useState(data.buttonText || 'Save PWA-design');

    const handleLandName = (name) => setLandName(name);
    useEffect(() => {
        handleLandName(data.landName);
    }, [data.landName]);

    const handleButtonText = (text) => {
      setButtonText(text);
      updateData({ ...data, buttonText: text });
    };

    const handleBuilderMode = (mode) => {
        setBuilderMode(mode);
        updateData({ ...data, builderMode: mode });
        
    };

    const currentUrl = window.location.href;
    const parsedParams = queryString.parseUrl(currentUrl);
    const builder_mode = parsedParams.query.builder_mode;
    const type_landing = parsedParams.query.type_land;

    const [responseMessage, setResponseMessage] = useState(undefined);
    const handleResponseMessage = (message) => setResponseMessage(message)

    useEffect(() => {
        if (responseMessage === 'success') {
            window.location.href = 'tg://resolve?domain=easy_links_bot';
        }

        if (responseMessage === 'error') {
            alert('Помилка при відправці в кейтаро \b Перейдіть заново по посиланню в ТГ ');
        }
    }, [handleResponseMessage, responseMessage]);
    
    useEffect(() => {
        if (builder_mode) {
            handleBuilderMode(builder_mode);
        }
    }, [builder_mode, type_landing]);

    useEffect(() => {
        const updatedData = {
            ...data,
            builderMode,
        };
        updateData(updatedData);
    }, [builderMode]);

    var dataSend = {};
    useEffect(() => {
        dataSend = {
            landingType: data.landingType,
            builderMode: data.builderMode,
            languageBilder: data.languageBilder,
            keitaroId: data.keitaroId,
            oneSignalId: data.oneSignalId,
            landName: data.landName,
            langCode: data.langCode,
            iconUrl: data.iconUrl,
            countComments: data.countComments,
            screenshotImages: data.screenshotImages,
            author: data.author,
            categories: data.categories,
            age: data.age,
            appSize: data.appSize,
            amountInstall: data.amountInstall,
            buttonText: data.buttonText,
            landDescriptionTitle: data.landDescriptionTitle,
            landDescriptionText: data.landDescriptionText,
            multiCategories: data.multiCategories,
            recommendedApp: data.recommendedApp,
            langReviewSelect: data.langReviewSelect,
            templateReview: data.templateReview,
            templateReviewCheck: data.templateReviewCheck,
            TemplateReviewStatus: data.TemplateReviewStatus,
            parseContents: data.parseContents,
            amountComments: data.amountComments,
            verifiedDeveloper: data.verifiedDeveloper,
            progressBars: data.progressBars,
            ratingStar: data.ratingStar,
            templateStatus: data.templateStatus,
            selectTemplate: data.selectTemplate,
            selectTemplateName: data.selectTemplateName,
            previewScreens: data.previewScreens
        }
    }, [data, builder_mode, dataSend, data.parseContents, buttonText, type_landing]);

    const [errorText, setErrorText] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    function SendDataPwa() {
        const error = ShowError({ dataSend });
        if (error) {
            setErrorText(error);
            setShowErrorPopup(true);
            setTimeout(() => {
                setShowErrorPopup(false);
            }, 2500);
            return;
        }

        const sendData = async () => {
            try {
                handleButtonText('Sending...');
                const url = 'https://pwa-builder.com/api/create-pwa';
                // const url = 'http://localhost:8181/api/create-pwa';
                // const url = 'https://test.pwa-builder.com/api/create-pwa';
                const response = await axios.post(url, dataSend);

                if (response.data === 'success') {
                    handleButtonText('Design successfully saved ✅');
                    handleResponseMessage('success');
                    window.location.href = 'tg://resolve?domain=easy_links_bot';
                } else {
                    handleButtonText('Design error saved ⛔️');
                    handleResponseMessage('error');
                    alert('Помилка при відправці в кейтаро \b Перейдіть заново по посиланню в ТГ');
                }
            } catch (error) {
                handleButtonText('Design error saved ⛔️');
                handleResponseMessage('error');
                alert('Сталася помилка при відправленні даних.');
            }
        };

        if (data.buttonText !== 'Design successfully saved ✅') {
            sendData();
        } else {
            setTimeout(() => {
                setShowErrorPopup(false);
            }, 100);
        }
    }

    useEffect(() => {
    }, [data.buttonText])

    const previewRef = useRef(null);
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 62) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // console.log(data, 'preview')
    }, [data])
    
    return (
        <div ref={previewRef} className={`${PreviewStyle.PreviewContainer} ${isFixed ? PreviewStyle.PWAPreviewFixed : PreviewStyle.PWAPreviewNormal}`}>
            {errorText && <ErrorPopup textError={errorText} />}
            {showErrorPopup && <ErrorPopup textError={errorText} />}
            <div className={PreviewStyle.HeaderPreview}>
                <div className={PreviewStyle.InfoDownload}>
                    <div className={PreviewStyle.InfoDownload_SendLand} onClick={SendDataPwa}>
                        <div className={PreviewStyle.InfoDownload_SendLand_Button}>
                            {buttonText}
                        </div>
                    </div>
                </div>
            </div>
                <PWAPreview />            
        </div>
    );
};

export default Preview;