import React, { useEffect, useState } from 'react';
import "./RatingStarStyle.css";

const RatingStars = ({ rating }) => {
    const [ratingNumber, setRatingNumber] = useState(rating);
    const handleRatingNumber = (rating) => {
        setRatingNumber(rating);
    };

     useEffect(() => {
        let replaceRating = String(rating).replace(/\,/g, '.');
        setRatingNumber(Number(replaceRating));
     }, [rating, ratingNumber]);

    return (
        <div className="rating_stars">
            <div className="stars-landing" id="stars-box" style={{'--rating': rating.length == 0 ? 4.5 : ratingNumber}}></div>
        </div>
    );
};

export default RatingStars;
