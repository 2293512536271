import {React, useCallback, useEffect, useState} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import NoBanner from '../no_banner.jpg';
import {useData} from "../../../Context/ContextApp";

const SectionScrollBanner = () => {
    let {data, updateData} = useData();
    const [screenshotImages, setScreenshotImages] = useState([]);
    useEffect(() => {
    }, [data])


    return (
        <section className={PWAPreviewStyle.scroll_1}>
            <div className={PWAPreviewStyle.scroll__container_1}>
                {
                    data.screenshotImages.length === 0 ?
                        <div className={`${PWAPreviewStyle.item_1} js-event`} >
                            <img src={NoBanner} alt="" style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                        </div> : <>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_1']  ? 'none' : 'inline-block'}}>
                                <img src={data.screenshotImages['screen_1'] || data.screenshotImages[0]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_2'] ? 'none' : 'inline-block'}}>
                                <img src={data.screenshotImages['screen_2'] || data.screenshotImages[1]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_3'] ? 'none' : 'inline-block'}} >
                                <img src={data.screenshotImages['screen_3'] || data.screenshotImages[2]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_4'] ? 'none' : 'inline-block'}}>
                                <img src={data.screenshotImages['screen_4'] || data.screenshotImages[3]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_5'] ? 'none' : 'inline-block'}}>
                                <img src={data.screenshotImages['screen_5'] || data.screenshotImages[4]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                            <div className={`${PWAPreviewStyle.item_1} js-event`} style={{display: !data.screenshotImages['screen_6'] ? 'none' : 'inline-block'}}>
                                <img src={data.screenshotImages['screen_6'] || data.screenshotImages[5]} alt=""
                                     style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                            </div>
                        </>
                }
            </div>
        </section>
    );
};

export default SectionScrollBanner;