import {React} from '../../imports/imports-packege';
import HeaderStyle from './HeaderStyle.module.scss';
import {MainLogo} from '../../imports/imports-images';
import HeaderButton from '../HeaderButton/HeaderButton';
import LanguagesSelect from './LanguagesSelect';

const Header =({ togglePreview, showPreview }) => {
    const HeaderButtonText = () => {
        if (!showPreview) {
            return 'Preview'
        }
        return 'Close'
    }
    return (
        <div className={HeaderStyle.container}>
            <div className={HeaderStyle.LogoNavBlock}>
                <div className={HeaderStyle.LogoContainer}>
                    <img src={MainLogo} alt="" className={HeaderStyle.LogoImage}/>
                </div>
            </div>

            <LanguagesSelect />
            <HeaderButton
                HeaderButtonText={HeaderButtonText}
                onClick={togglePreview}
            />
        </div>
    );
};

export default Header;