import {React, useState, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import StarBlue from '../images/icon_star_blue.png';
import StarGray from '../images/icon_star_gray.png';
import RectangleRatingLine5 from '../images/rect_blue_5.png';
import RectangleRatingLine4 from '../images/rect_blue_4.png';
import RectangleRatingLine3 from '../images/rect_blue_3.png';
import RectangleRatingLine2 from '../images/rect_blue_2.png';
import RectangleRatingLine1 from '../images/rect_blue_1.png';
import { useData } from "../../../Context/ContextApp";
import RatingStars from '../../../UI/RatingStar/RatingStar';
import TranslateText from '../Translate/text-translate.json';

const SectionRating = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];
    const progressBars = data.progressBars;
    const rating = data.ratingStar;

    useEffect(() => {
    }, [data.RatingStar])

    return (
        <section className={PWAPreviewStyle.rewievs}>
            <h1>
                {data.langCode.length > 0 ? ListTextSecurity['title_review']: 'Rating and review' }
            </h1>
            <div className={PWAPreviewStyle.rating}>
                <p className={PWAPreviewStyle.rating__left}>{rating}</p>
                <div className={PWAPreviewStyle.rating__right}>
                {[5, 4, 3, 2, 1].map((rating, index) => (
                    <div key={rating} className={PWAPreviewStyle.rating__item}>
                        <p className={PWAPreviewStyle.item__number}>{rating}</p>
                        <div className={PWAPreviewStyle.item__img}>
                            <progress value={progressBars[index] / 100} max="1"/>
                        </div>
                    </div>
                ))}
                </div>
            </div>

            <div className={PWAPreviewStyle.rating__stars}>
                <div className={PWAPreviewStyle.stars}>
                    <RatingStars rating={rating} />
                </div>

                <p className={PWAPreviewStyle.stars__content}>{data.amountComments ? data.amountComments : '2455'}</p>
            </div>
        </section>
    );
};

export default SectionRating;