import {React, useState, useCallback, useEffect} from '../../../../imports/imports-packege';
import {SelectList} from '../../../../imports/imports-components';
import AppDesignStyle from "../AppDesignStyle.module.scss";
import {TemplateAppScreen} from "../../../SelectOption/SelectOption";
import {useData} from "../../../Context/ContextApp";
import ItemPreviewTemplate from "./ItemPreviewTemplate";

const TemplateDesign = () => {
    const { data, updateData } = useData();
    const [selectTemplate, setSelectTemplate] = useState(data.selectTemplate);
    const handleSelectTemplate = useCallback((template) => {
        setSelectTemplate(template);
    },[selectTemplate]);

    useEffect(() => {
        if (selectTemplate) {
            updateData({
                ...data,
                selectTemplate: selectTemplate
            })
        }
    }, [selectTemplate]);

    return (
        <div className={AppDesignStyle.SectionTemplate}>
            <SelectList
                optionList={TemplateAppScreen}
                getSelectValue={e => handleSelectTemplate(e)}
                value={selectTemplate}
            />

            {
                selectTemplate && (
                    <ItemPreviewTemplate  />
                )
            }
        </div>
    );
};

export default TemplateDesign;