import {React, useEffect, useState} from '../../imports/imports-packege';
import {useData} from "../Context/ContextApp";
import ErrorPopupStyle from './ErrorPopupStyle.module.scss';
import {useRef} from "react";

const ErrorPopup = ({textError}) => {
    const [isVisible, setIsVisible] = useState(false);
    const isVisibleRef = useRef(isVisible);

    useEffect(() => {
        isVisibleRef.current = isVisible;
    }, [isVisible]);

    useEffect(() => {
        const storedVisibility = localStorage.getItem('errorPopupVisibility');
        if (textError === undefined) {
            return;
        }
        setIsVisible(true);

        const timerId = setTimeout(() => {
            if (isVisibleRef.current) {
                setIsVisible(false);
            }
        }, 3000);

        return () => {
            clearTimeout(timerId);
        };
    }, [textError]);

    useEffect(() => {
        localStorage.setItem('errorPopupVisibility', isVisibleRef.current ? 'true' : 'false');
    }, [isVisibleRef.current]);

    return (
        <div className={`${ErrorPopupStyle.ErrorPopup} ${isVisible ? ErrorPopupStyle.visible : ''}`}>
            <div className={ErrorPopupStyle.content}>
                <p className={ErrorPopupStyle.TitleText}>Error field</p>
                <p className={ErrorPopupStyle.Text}>{textError}</p>
            </div>

        </div>
    );
};

export default ErrorPopup;