import {React} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import IconShare from '../images/icon_share.svg';
import IconBlock from '../images/icon_lock.png';
import { useData } from "../../../Context/ContextApp";
import TranslateText from '../Translate/text-translate.json';

const SectionDataSecurity = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <section className={PWAPreviewStyle.data_security}>
            <h1>{data.langCode.length > 0 ? ListTextSecurity['data_save_text'] : 'Security  data'}</h1>
            <div className={PWAPreviewStyle.data_security__content}>
                <p>
                    <img src={IconShare} alt=""/>
                    {data.langCode.length > 0 ? ListTextSecurity['data_save_1'] : 'This application does not share data with third parties'}
                </p>
                <p>
                    <img src={IconBlock} alt=""/>
                    {data.langCode.length > 0 ? ListTextSecurity['data_save_2'] : 'Data is encrypted during transmission'}
                </p>
            </div>
        </section>
    );
};

export default SectionDataSecurity;