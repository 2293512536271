import {React, BrowserRouter, useEffect, axios, useState, queryString} from '../../imports/imports-packege';
import MainContentSectionStyle from './MainContentSectionStyle.module.scss';
import {Navigation, Preview} from "../../imports/imports-components";
import {useData} from "../Context/ContextApp";
import {getConfig} from "@testing-library/react";

const MainContentSection = ({ showPreview, hideNav }) => {
    const { data, updateData } = useData();
    let getUserQuery = new URLSearchParams(window.location.search);
    let getQueryTypeCreate = getUserQuery.get('builder_mode');
    let getQueryKeitaroId = getUserQuery.get('keitaro_id');

    const [oneSignalId, setOneSignalId] = useState('');
    const [keitaroId, setKeitaroId] = useState('');
    const [landingType, setLandingType] = useState('');
    const handlerOneSignalId = (query_appId) => setOneSignalId(query_appId);
    const handlerKeitaroId = (query_keitaroId) => setKeitaroId(query_keitaroId);
    const handleLandingType = (type_land) => setLandingType(type_land);

    const currentUrl = window.location.href;
    const parsedParams = queryString.parseUrl(currentUrl);
    const onesignal_id = parsedParams.query.onesignal_id;
    const type_land = parsedParams.query.type_land;

    useEffect(() => {
      handlerKeitaroId(!getQueryKeitaroId ? data.keitaroId : getQueryKeitaroId);
      handlerOneSignalId(!onesignal_id ? data.oneSignalId : onesignal_id);
      handleLandingType(!type_land ? data.landingType : type_land);
      updateData({
          ...data,
          oneSignalId: oneSignalId,
          keitaroId: keitaroId,
          landingType: landingType
      })
    }, [keitaroId, onesignal_id, landingType]);

    async function getConfigFile (keitaro_id) {
        try {
            // let response = await axios.get(`http://localhost:8181/api/get-config?keitaro_id=${keitaro_id}`);
            // let response = await axios.get(`https://test.pwa-builder.com/api/get-config?keitaro_id=${keitaro_id}`);
            let response = await axios.get(`https://pwa-builder.com/api/get-config?keitaro_id=${keitaro_id}`);
            let data = JSON.parse(response.data.data);
            if (!data.templateReview[0]['name']) {
                data.templateReview = [];
                alert('Для вашої ПВА були згенеровані нові коментарі');
            }
            if(!data.builderMode) {
                alert('Your PWA version is old, editing is only available for new PWAs');
                window.location.href = 'tg://resolve?domain=easy_links_bot';
                return false;
            }
            return data;
        } catch (error) {
            console.log(`Error get config ${keitaro_id}: ${error}`)
        }
    }
    useEffect(() => {
        if (getQueryTypeCreate === 'edit') {
            getConfigFile(getQueryKeitaroId)
                .then((configData) => {
                    updateData(configData);
                    updateData({
                        parseContents: false
                    })
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    }, [
        getQueryTypeCreate,
        getQueryKeitaroId
    ]);

    return (
        <div className={MainContentSectionStyle.MainSection}>
            <div className={MainContentSectionStyle.MainSection_NavigationEditSection}
                 style={{display: hideNav ? 'block' : 'none'}}>
                <BrowserRouter>
                    <Navigation />
                </BrowserRouter>
            </div>
            <div className={MainContentSectionStyle.PreviewSection}>
                {showPreview && <Preview />}
            </div>
        </div>
    );
};

export default MainContentSection;