import { React, useState, useEffect, useCallback } from '../../../../imports/imports-packege';
import TemplateImages from "./template-design.json";
import { useData } from "../../../Context/ContextApp";
import AppDesignStyle from "../AppDesignStyle.module.scss";

const ItemPreviewTemplate = () => {
    const { data, updateData } = useData();
    const [templateName, setTemplateName] = useState(data.selectTemplate);

    useEffect(() => {
        if (data.selectTemplate) {
            setTemplateName(data.selectTemplate);
        }
    }, [data.selectTemplate, templateName]);

    useEffect(() => {
    }, [templateName]);

    const ViewTemplate = function(nameTemplate) {
        let listTemplate = TemplateImages[nameTemplate];
        let arrayImages = [];
        for (let item in listTemplate) {
            arrayImages.push({
                name: item,
                icon: listTemplate[item]['icon']
            });
        }
        return arrayImages;
    }

    function GetChildsNodes () {
        let containerListTemplate = document.getElementsByClassName(`${AppDesignStyle.IconTemplate}`);
        let arrayNodes = [];
        if (containerListTemplate.length > 0) {
            let firstContainer = containerListTemplate[0];
            let childNodesArray = Array.from(firstContainer.childNodes);
            childNodesArray.forEach((node, index) => {
                arrayNodes.push(node)
            });
        }
        return arrayNodes;
    }

    let addSelectedScreen = function (itemTemplate) {
        let nameList = itemTemplate.slice(0, -2);
        let imageList = TemplateImages[nameList][itemTemplate];

        let updatedScreenshotImages = {
            ...data.screenshotImages,
            [`screen_1`]: imageList['screen_1'],
            [`screen_2`]: imageList['screen_2'],
            [`screen_3`]: imageList['screen_3'],
            [`screen_4`]: imageList['screen_4'],
            [`screen_5`]: imageList['screen_5']
        };

        // Видаляємо screen_6, якщо він існує
        if (updatedScreenshotImages['screen_6']) {
            delete updatedScreenshotImages['screen_6'];
            delete updatedScreenshotImages['icon'];
        }

        updateData({
            ...data,
            selectTemplateName: itemTemplate,
            iconUrl: imageList['icon'],
            [`screen_1`]: imageList['screen_1'],
            [`screen_2`]: imageList['screen_2'],
            [`screen_3`]: imageList['screen_3'],
            [`screen_4`]: imageList['screen_4'],
            [`screen_5`]: imageList['screen_5'],
            screenshotImages: updatedScreenshotImages,
            previewScreens: [
                imageList['screen_1'],
                imageList['screen_2'],
                imageList['screen_3'],
                imageList['screen_4'],
                imageList['screen_5']
            ]
        });
    };


    const [selectedNameTemplate, setSelectedNameTemplate] = useState(data.selectedTemplate);
    let handleTemplateName = useCallback((elem) => {
        let element = elem.target;
        let dataName = element.dataset.name;
        let nodes = GetChildsNodes();
        nodes.forEach((el) => {
            if (el.classList.length > 1) {
                el.classList.remove(`${AppDesignStyle.ActiveIconTemplate}`)
            }
        })
        element.parentNode.classList.add(`${AppDesignStyle.ActiveIconTemplate}`);
        setSelectedNameTemplate(dataName);
    }, [selectedNameTemplate]);

    useEffect(() => {
        if (selectedNameTemplate) {
            addSelectedScreen(selectedNameTemplate);
        }
    }, [selectedNameTemplate])

    useEffect(() => {
        let nodes = GetChildsNodes();
        nodes.forEach((el) => {
            if (el.classList.length > 1) {
                el.classList.remove(`${AppDesignStyle.ActiveIconTemplate}`)
            }
        })
    }, [templateName])

    return (
        <div className={AppDesignStyle.IconTemplate}>
            {templateName && (
                ViewTemplate(templateName).map((item, index) => (
                    <div className={`${AppDesignStyle.ContainIconTemplate}`} key={index}>
                        <img
                            src={item.icon}
                            alt={`template-icon-${index}`}
                            data-name={item.name}
                            onClick={handleTemplateName}
                        />
                    </div>
                ))
            )}
        </div>
    );
};

export default ItemPreviewTemplate;
