import './assets/style/AppStyle.css';
import {MainContentSection, Header} from "./imports/imports-components";
import React, { useState, useEffect } from 'react';
import {DataProvider} from './components/Context/ContextApp';
import {axios} from "./imports/imports-packege";

function App() {
    const [showPreview, setShowPreview] = useState(true);
    let [hideNav, setHideNav] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        const isWideScreen = window.matchMedia("(min-width: 1240px)").matches;
        setShowPreview(isWideScreen);
        setHideNav(isWideScreen)
        if (!isWideScreen) {
          setHideNav(true);
        }
      };
      
      handleResize();
  
      const mediaQuery = window.matchMedia("(min-width: 1240px)");
      mediaQuery.addEventListener('change', handleResize);
  
      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }, []);

    const togglePreview = () => {
        setShowPreview(prevState => !prevState);
        setHideNav(prevState => !prevState);
    };
    const review = async () => {
        await axios.get('http://localhost:8181/api/chat-res')
            .then(res => {
                console.log(res.data)
            })
    }
    // console.log(review());
    return (
        <div className="MainFullScreen">
            <DataProvider>
                <Header
                    togglePreview={togglePreview}
                    showPreview={showPreview}
                />
                <MainContentSection
                    showPreview={showPreview}
                    hideNav={hideNav}
                />
            </DataProvider>
        </div>
    );
}

export default App;
