import {React, useEffect} from '../../../imports/imports-packege';
import AppReviewStyle from "./AppReviewStyle.module.scss"

const Loader = () => {
    return (
        <div className={AppReviewStyle.LoaderContainer}>
            <span className={AppReviewStyle.Loader}></span>
        </div>
    );
};

export default Loader;